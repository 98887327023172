<template>
    <div class="single-event">
        <div class="container">
            <div class="d-flex flex-column flex-sm-row align-items-center justify-content-between py-2 py-sm-3 mb-3 mb-sm-0">
                <div v-if="loading" class="mb-2 mb-sm-0 text-center">
                    <h4  class="text-brand font-weight-600 mb-0">
                        <b-skeleton width="12rem" height="1.5rem"></b-skeleton>
                    </h4>
                    <span class="text-gray">
                        <b-skeleton width="10rem" height="1rem"></b-skeleton>
                    </span>
                </div>
                <div v-else class="mb-2 mb-sm-0 text-center">
                    <h4  class="text-brand font-weight-600 mb-0 text-capitalize">
                        {{ event.title }}
                    </h4>
                    <span class="text-gray">
                        {{ event.business_unit }}
                        <span v-if="event.business_unit && event.subBusinessUnit">/</span> 
                        {{ event.subBusinessUnit }}
                    </span>
                    <!-- <small class="text-gray mb-3"></small> -->
                </div>
                <div v-if="loading" class="d-flex align-items-center">
                    <div class="d-flex align-items-center mx-2">
                        <b-skeleton width="12rem" height="1.5rem"></b-skeleton>
                    </div>
                </div>
                <div v-else class="d-flex align-items-center">
                    <div class="d-flex align-items-center mx-2 whitespace-nowrap">
                        <span class="mx-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.1rem"
                                height="1.1rem"
                                viewBox="0 0 18 20"
                            >
                                <path
                                    d="M10.5,12h-2v2h2Zm4,0h-2v2h2Zm4,0h-2v2h2Zm2-7h-1V3h-2V5h-8V3h-2V5h-1A1.991,1.991,0,0,0,4.51,7L4.5,21a2,2,0,0,0,2,2h14a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,20.5,5Zm0,16H6.5V10h14Z"
                                    transform="translate(-4.5 -3)"
                                    fill="#959595"
                                />
                            </svg>
                        </span>
                        <span class="text-gray">
                            {{ $moment(event.time).format("YYYY-MM-DD") }}
                        </span>
                    </div>
                    <div class="d-flex align-items-center whitespace-nowrap">
                        <span class="mx-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1.1rem"
                                height="1.1rem"
                                viewBox="0 0 19.5 19.5"
                            >
                                <path
                                    d="M13.116,3.375a9.75,9.75,0,1,0,9.759,9.75A9.747,9.747,0,0,0,13.116,3.375Zm.009,17.55a7.8,7.8,0,1,1,7.8-7.8A7.8,7.8,0,0,1,13.125,20.925Z"
                                    transform="translate(-3.375 -3.375)"
                                    fill="#959595"
                                />
                                <path
                                    d="M18,10.688H16.538v5.85l5.119,3.07.731-1.2L18,15.806Z"
                                    transform="translate(-7.763 -5.813)"
                                    fill="#959595"
                                />
                            </svg>
                        </span>
                        <span class="text-gray">
                            {{ $moment(event.time).format("hh:mm A") }}
                        </span>
                    </div>
                </div>
            </div>
            <div v-if="loading" class="single-event-img">
                <div class="first-event-img">
                    <b-skeleton width="100%" height="20rem"></b-skeleton>
                </div>
            </div>
            <div v-else>
                <div class="single-event-img">
                    <img
                        class="first-event-img"
                        :src="event.photo"
                        :alt="event.title"
                    />
                    <!-- <progressive-img
                    class="first-event-img"
                    :src="event.photo"
                    :alt="event.title"/> -->
                </div>
                <!-- event content -->
                <div class="d-flex flex-column flex-xl-row pt-lg-3">
                    <div class="single-event-body">
                        <div class="d-flex align-items-center mb-2">
                            <img
                                class="partner-img rounded-circle"
                                :src="event.partner_logo"
                                :alt="event.partner"
                            />
                            <h6 class="mx-2 mb-0 font-weight-600 text-capitalize">
                                {{ event.partner }}
                            </h6>
                        </div>
                        <pre class="mb-4 text">
                            {{ event.body }}
                        </pre>
                        <h6 v-if="event.attend" class="text-gray">How to attend?</h6>
                        <div v-if="event.attend" class="how-to-attend p-2 p-sm-4 mb-4">
                            <pre>{{ event.attend }}</pre>
                            <!-- <pre>
                                1- Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
                                2- Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
                                3- Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
                                4- Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
                            </pre> -->
                        </div>
                        <div v-if="event.website">
                            <h6 class="text-gray">Event URL</h6>
                            <div class="event-url p-2 p-sm-4 mb-4">
                                <a :href="event.website" target="_blank" class="d-flex align-items-center">
                                    <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 26.75 26.75">
                                    <path d="M16.362,3A13.375,13.375,0,1,0,29.75,16.375,13.368,13.368,0,0,0,16.362,3Zm9.269,8.025H21.685a20.931,20.931,0,0,0-1.846-4.761A10.74,10.74,0,0,1,25.63,11.025Zm-9.255-5.3a18.841,18.841,0,0,1,2.555,5.3H13.82A18.841,18.841,0,0,1,16.375,5.728ZM6.023,19.05a10.462,10.462,0,0,1,0-5.35h4.521a22.089,22.089,0,0,0-.187,2.675,22.089,22.089,0,0,0,.187,2.675Zm1.1,2.675h3.946a20.93,20.93,0,0,0,1.846,4.761A10.682,10.682,0,0,1,7.119,21.725Zm3.946-10.7H7.119a10.682,10.682,0,0,1,5.791-4.761A20.93,20.93,0,0,0,11.065,11.025Zm5.31,16a18.841,18.841,0,0,1-2.555-5.3H18.93A18.841,18.841,0,0,1,16.375,27.021ZM19.5,19.05H13.245a19.678,19.678,0,0,1-.214-2.675,19.507,19.507,0,0,1,.214-2.675H19.5a19.507,19.507,0,0,1,.214,2.675A19.678,19.678,0,0,1,19.5,19.05Zm.334,7.436a20.931,20.931,0,0,0,1.846-4.761H25.63a10.74,10.74,0,0,1-5.791,4.761Zm2.367-7.436a22.089,22.089,0,0,0,.187-2.675,22.089,22.089,0,0,0-.187-2.675h4.521a10.462,10.462,0,0,1,0,5.35Z" transform="translate(-3 -3)" fill="#c6c6c6"/>
                                    </svg>
                                    </span>
                                    <span class="d-inline-block mx-2">
                                        {{ event.website }}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <aside class="single-event-sidebar">
                        <h6 class="text-gray mb-3">Related Events</h6>
                        <p v-if="noRelated" class="bg-gray py-1 px-2 pt-2">{{ noRelatedMsg }}</p>
                        <router-link :to="`/events/${ event.id }/${ event.title }`" v-for="(event, index) in relatedEvents" :key="index" class="related-event d-block mb-4">
                            <img class="img" :src="event.photo" :alt="event.title">
                            <!-- <progressive-img
                            class="img"
                            :src="event.photo" :alt="event.title"/> -->
                            <div class="p-2 bg-gray">
                                <h5 class="mb-1">{{ event.title }}</h5>
                                <small class="text-gray mb-3">
                                    {{ event.business_unit }} 
                                    <small v-if="event.business_unit && event.subBusinessUnit">
                                        /
                                    </small> 
                                    {{ event.subBusinessUnit }}
                                </small>
                                <div class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-start justify-content-sm-between">
                                    <div class="d-flex align-items-center mb-2 mb-sm-0">
                                        <img class="partner-img rounded-circle"  :src="event.partner_logo" :alt="event.partner"/>
                                        <small class="mx-2 mb-0">
                                            {{ event.partner }}
                                        </small>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div
                                            class="
                                                d-flex
                                                align-items-center
                                                mx-2
                                                whitespace-nowrap
                                            ">
                                            <span class="mx-1">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1rem"
                                                    height="1rem"
                                                    viewBox="0 0 18 20"
                                                >
                                                    <path
                                                        d="M10.5,12h-2v2h2Zm4,0h-2v2h2Zm4,0h-2v2h2Zm2-7h-1V3h-2V5h-8V3h-2V5h-1A1.991,1.991,0,0,0,4.51,7L4.5,21a2,2,0,0,0,2,2h14a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,20.5,5Zm0,16H6.5V10h14Z"
                                                        transform="translate(-4.5 -3)"
                                                        fill="#959595"
                                                    />
                                                </svg>
                                            </span>
                                            <small class="text-gray">
                                                {{ $moment(event.time).format("YYYY-MM-DD") }}
                                            </small>
                                        </div>
                                        <div class="d-flex align-items-center whitespace-nowrap">
                                            <span class="mx-1">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1rem"
                                                    height="1rem"
                                                    viewBox="0 0 19.5 19.5"
                                                >
                                                    <path
                                                        d="M13.116,3.375a9.75,9.75,0,1,0,9.759,9.75A9.747,9.747,0,0,0,13.116,3.375Zm.009,17.55a7.8,7.8,0,1,1,7.8-7.8A7.8,7.8,0,0,1,13.125,20.925Z"
                                                        transform="translate(-3.375 -3.375)"
                                                        fill="#959595"
                                                    />
                                                    <path
                                                        d="M18,10.688H16.538v5.85l5.119,3.07.731-1.2L18,15.806Z"
                                                        transform="translate(-7.763 -5.813)"
                                                        fill="#959595"
                                                    />
                                                </svg>
                                            </span>
                                            <small class="text-gray">
                                            {{ $moment(event.time).format("hh:mm A") }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </aside>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getSingleEvent, getRelatededEvent } from "@/API/events";
export default {
    name: "Event",
    data() {
        return {
            loading: true,
            event: {},
            relatedEvents: [],
            noRelated: false,
            noRelatedMsg: null
        }
    },
    computed: {
        eventID() {
            return parseInt(this.$route.params.id);
        }
    },
    methods: {
        getEvent() {
            getSingleEvent(this.eventID).then(res => {
                this.loading = false;
                if (res.status == 200) {
                    // console.log(data);
                    const data = res.data?.data;
                    this.event = data;
                }

                if (res?.status == 404) {
                    this.$router.push("/404");
                    return;
                }

                if (res?.status == 500) {
                    this.$router.push("/500");
                    return;
                }
            });
        },
        getRelatedEvents() {
            getRelatededEvent(this.eventID).then(res => {
                this.loadingSuggested = false;
                if (res.status == 200) {
                    const data = res.data?.data?.event;
                    // console.log(res.data);
                    if (!data && res.data?.message) {
                        this.noRelated = true
                        this.noRelatedMsg = res.data.message
                        return;
                    }
                    this.relatedEvents = data;
                }

                if (res?.response?.status == 500) {
                    this.$router.push("/500");
                    return;
                }
            });
        },
    },
    mounted() {
        this.getEvent();
        this.getRelatedEvents();
    }
};
</script>